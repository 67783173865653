import AsyncStorage from '@react-native-async-storage/async-storage';
import {useDrawerStatus} from '@react-navigation/drawer';
import {useIsFocused} from '@react-navigation/native';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {Platform, SafeAreaView, View, useWindowDimensions, BackHandler} from 'react-native';
import {LinearProgress, useTheme} from 'react-native-elements';
import useSWR from 'swr';
import {REST_COMMUNICATION} from '../api/inCheckinRestService';
import MessageDialog from '../components/MessageDialog';
import CheckinsList from '../components/checkins/CheckinsList';
import EVChooserHeader from '../components/checkins/EVChooserHeader';
import FabButton from '../components/checkins/FabButton';
import MultiStepCheckinCreatorDialog from '../components/checkins/MultiStepCheckinCreatorDialog';
import {useSharedDialogs} from '../components/checkins/useDialogs';
import AddEvisitorDialog from '../components/evaccounts/AddEvisitorDialog';
import EVChooserDialog from '../components/tourists/EVChooserDialog';
import Dropdown from '../components/ui-components/Dropdown/Dropdown';
import {
    ACCOMODATION_OBJECT,
    CHECKIN_SORT_OPTIONS,
    DATE_OF_ARRIVAL,
    DATE_OF_DEPARTURE,
    FIRST_ADDED,
    LAST_ADDED,
    LOGIN_REFRESH_DIALOG_TEXT,
    PIN,
    STORAGE_CHOSEN_EV_ACCOUNT,
} from '../constants/stringsAndFields';
import DataContext from '../context/dataContext';
import globalStyle from '../theme/globalStyle';
import {findEvisitorByPin, isEmpty, isNotEmpty} from '../utils/arrayHelpers';
import {initIAPSubscriptions} from '../utils/iaps';
import {
    getCheckinsYear,
    getSortingLastSettings,
    isEVAccountFetchedFacility,
    refreshUserCredits,
    saveEVAccountFetchedFacilityFlag,
    setCheckinsYear,
    setSortingLastSettings,
} from '../utils/userUtils';
import {INVOICES_ALL_PAGES, INVOICE_FIRST_PAGE} from './InvoicesScreen';
import {getCurrentYear} from '../utils/dateHelper';

const IS_IOS = Platform.OS === 'ios';
const IS_WEB = Platform.OS === 'web';
export const CHECKINS_PAGE_COUNT = 25;
export const CHECKINS_FIRST_PAGE = 0;
export const CHECKINS_ALL_PAGES = -1;
const dropdownYearOptions = [{text: '2022'}, {text: '2023'}, {text: '2024'}, {text: '2025'}];

const CheckinsScreen = ({route, navigation}) => {
    const {
        clearErrors,
        initCheckins,
        getCheckinsLocal,
        checkins = [],
        evaccounts,
        refetchEvisitor,
        associateEvisitor,
        apiMessages,
        apiErrors,
        reauthOngoing,
        loggedIn,
        startupCompleted,
        refreshInvoices,
        hasNextCheckins,
    } = useContext(DataContext);
    const {t} = useTranslation();
    const {theme} = useTheme();
    const global = globalStyle(theme);
    const isFocused = useIsFocused();
    const [evAccount, setEvAccount] = useState(null);
    const [progressOngoing, setProgressOngoing] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [openMessage, setOpenMessage] = useState(false);
    const [openAddEvisitor, setOpenAddEvisitor] = useState(false);
    const [openUserReauth, setOpenUserReauth] = useState(false);
    const [evAccountSetupInProgress, setEvAccountSetupInProgress] = useState(false);
    const [sortCheckinsBy, setSortCheckinsBy] = useState(null);
    const [yearFilter, setYearFilter] = useState(getCurrentYear());
    const [evAccountError, setEvAccountError] = useState(false);
    const width = useWindowDimensions().width;
    const isTablet = width >= 500 && width < theme.desktopBP;

    const isDrawerOpen = useDrawerStatus() === 'open';
    useSWR(!IS_WEB && (isDrawerOpen || isTablet) ? 'refreshUserCredits' : null, refreshUserCredits);
    useSWR(IS_WEB ? 'refreshUserCredits' : null, refreshUserCredits);

    const dropdownSortOptions = [
        {text: t(LAST_ADDED), sortBy: LAST_ADDED},
        {text: t(FIRST_ADDED), sortBy: FIRST_ADDED},
        {text: t(DATE_OF_ARRIVAL), sortBy: DATE_OF_ARRIVAL},
        {text: t(DATE_OF_DEPARTURE), sortBy: DATE_OF_DEPARTURE},
    ];

    const onSelectedSortOption = async option => {
        const sortOption = option.sortBy;
        setSortCheckinsBy(sortOption);
        const settings = await getSortingLastSettings();
        setSortingLastSettings({...settings, [CHECKIN_SORT_OPTIONS]: sortOption});
    };

    const onRefresh = useCallback(async () => {
        setRefresh(true);
        const stay_year = await getCheckinsYear();
        await refreshInvoices(evAccount, evaccounts, INVOICE_FIRST_PAGE, true, stay_year);
        await initCheckins(CHECKINS_FIRST_PAGE, {evisitor_oib: evAccount[PIN], stay_year: stay_year}, true);
        setRefresh(false);
    }, [evAccount]);

    const openEvisitorModal = useCallback(() => {
        setOpenAddEvisitor(true);
    }, []);

    const onConnectEvisitorAccount = async eVisitorCredentials => {
        setProgressOngoing(true);
        setOpenAddEvisitor(false);
        try {
            const initializedEvAccount = await associateEvisitor(eVisitorCredentials);
            if (initializedEvAccount) {
                await setEvAccountCallback(initializedEvAccount);
            }
        } catch (e) {
            console.log(e);
            setEvAccountError(true);
            setOpenAddEvisitor(true);
        } finally {
            setProgressOngoing(false);
        }
    };

    const redrawHeaderComponents = useCallback(() => {
        navigation.setOptions({
            headerRight: () => (
                <EVChooserHeader
                    evAccount={evAccount}
                    useSharedDialogs={useSharedDialogs}
                    onRefresh={onRefresh}
                    global={global}
                    theme={theme}
                />
            ),
        });
    }, [evAccount, global, theme]);

    const initEVAccount = async () => {
        setEvAccountSetupInProgress(true);
        let checkinsRefreshed = false;
        try {
            const account = await setupEvAccount();
            if (account) {
                checkinsRefreshed = await checkAndFetchFacilities(account);
            }
            return {account: account, refreshed: checkinsRefreshed};
        } catch (e) {
            console.log(e);
        } finally {
            setEvAccountSetupInProgress(false);
        }
        return null;
    };

    const setupEvAccount = async () => {
        const storedEvAccPin = await AsyncStorage.getItem(STORAGE_CHOSEN_EV_ACCOUNT);
        let evAccountData = null;
        if (isNotEmpty(evaccounts)) {
            const firstEvAccount = evaccounts[0];
            if (storedEvAccPin) {
                evAccountData = findEvisitorByPin(evaccounts, storedEvAccPin);
                if (evAccountData) {
                    await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, evAccountData[PIN]);
                    // setEvAccount only if necessary
                    if (!evAccount || evAccount[PIN] !== evAccountData[PIN]) {
                        setEvAccount(evAccountData);
                    }
                }
            } else {
                if (firstEvAccount) {
                    await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, firstEvAccount[PIN]);
                    setEvAccount(firstEvAccount);
                    evAccountData = firstEvAccount;
                }
            }
            if (evAccountData) {
                await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, evAccountData[PIN]);
            } else if (firstEvAccount) {
                await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, firstEvAccount[PIN]);
            }
        } else {
            setEvAccount(null);
            redrawHeaderComponents();
        }
        return evAccountData;
    };

    const checkAndFetchFacilities = async activeEvAccount => {
        let checkinsRefreshed = false;
        const facilities = activeEvAccount ? activeEvAccount?.[ACCOMODATION_OBJECT] : [];
        if (isEmpty(facilities)) {
            const isAlreadyFetched = await isEVAccountFetchedFacility(activeEvAccount[PIN]);
            if (!isAlreadyFetched) {
                await refetchEvisitor(activeEvAccount);
                await saveEVAccountFetchedFacilityFlag(activeEvAccount[PIN]);
                const stay_year = await getCheckinsYear();
                await refreshInvoices(activeEvAccount, evaccounts, INVOICE_FIRST_PAGE, true, stay_year);
                // refresh checkins with new invoices
                const localCheckin = await getCheckinsLocal(activeEvAccount); // if empty try remote checkins
                if (isEmpty(localCheckin)) {
                    await initCheckins(
                        CHECKINS_FIRST_PAGE,
                        {evisitor_oib: activeEvAccount[PIN], stay_year: stay_year},
                        true
                    );
                }
                checkinsRefreshed = true;
            }
        }
        return checkinsRefreshed;
    };

    const updateHeader = async acc => {
        await AsyncStorage.setItem(STORAGE_CHOSEN_EV_ACCOUNT, acc[PIN]);
        redrawHeaderComponents();
    };

    const initEvAccountData = async acc => {
        setEvAccountSetupInProgress(true);
        try {
            const checkinsRefreshed = await checkAndFetchFacilities(acc);
            return checkinsRefreshed;
        } catch (e) {
            console.log(e);
        } finally {
            setEvAccountSetupInProgress(false);
        }
        return null;
    };

    const getNextPageCheckins = useCallback(
        async page => {
            try {
                setProgressOngoing(true);
                const stay_year = await getCheckinsYear();
                await refreshInvoices(evAccount, evaccounts, INVOICES_ALL_PAGES, true, stay_year);
                const fetchedCheckins = await initCheckins(
                    CHECKINS_ALL_PAGES,
                    {evisitor_oib: evAccount[PIN], stay_year: stay_year},
                    true
                );
                return fetchedCheckins;
            } catch (e) {
                console.log(e);
            } finally {
                setProgressOngoing(false);
            }
            return null;
        },
        [evAccount, evaccounts]
    );

    const onSelectedYear = async selectedYear => {
        await setCheckinsYear(selectedYear);
        await refreshInvoices(evAccount, evaccounts, INVOICE_FIRST_PAGE, true, selectedYear);
        await initCheckins(CHECKINS_FIRST_PAGE, {evisitor_oib: evAccount[PIN], stay_year: selectedYear}, true);
        setYearFilter(selectedYear);
    };

    const generateMessage = useCallback(() => {
        if (apiErrors) {
            return `${apiErrors?.signal}: ${apiErrors?.message}`;
        }
        if (apiMessages) {
            if (apiMessages?.signal === REST_COMMUNICATION) {
                return `${apiMessages?.message}`;
            }
            return `${apiMessages?.signal}: ${apiMessages?.message}`;
        }
        return null;
    }, [apiErrors, apiMessages]);

    useEffect(() => {
        const listener = navigation.addListener('beforeRemove', e => {
            if (IS_IOS) return;
            e.preventDefault();
            BackHandler.exitApp();
        });
        return listener;
    }, [navigation]);

    useEffect(() => {
        redrawHeaderComponents();
        getSortingLastSettings().then(sortingSettings => {
            sortingSettings && setSortCheckinsBy(sortingSettings[CHECKIN_SORT_OPTIONS]);
            getCheckinsYear().then(year => setYearFilter(year));
        });
    }, []);

    useEffect(() => {
        if (IS_IOS) {
            return initIAPSubscriptions();
        }
    }, []);

    // on each focus refresh checkins
    useEffect(() => {
        isFocused && clearErrors();
        if (isFocused) {
            if (!startupCompleted) {
                setProgressOngoing(true);
            } else {
                setProgressOngoing(false);
            }

            if (reauthOngoing && !loggedIn) {
                setOpenUserReauth(true);
                setProgressOngoing(true);
            } else {
                setOpenUserReauth(false);
                if (!loggedIn && startupCompleted) {
                    setProgressOngoing(false);
                    navigation.navigate('Login');
                } else {
                    if (startupCompleted) {
                        initEVAccount().then(results => {
                            if (results) {
                                const {account, refreshed} = results;
                                if (!refreshed) {
                                    getCheckinsLocal(account)
                                        .then(_ => {
                                            setProgressOngoing(false);
                                        })
                                        .catch(console.error);
                                }
                            }
                        });
                    }
                }
            }
        }
        return () => {
            setEvAccountSetupInProgress(false);
        };
    }, [isFocused, reauthOngoing, loggedIn, startupCompleted]);

    useEffect(() => {
        if (!evAccountSetupInProgress && startupCompleted && evAccount) {
            initEVAccount(false).catch(console.error);
        }
        return () => {
            setEvAccountSetupInProgress(false);
        };
    }, [evaccounts]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (evaccounts && evAccount) {
            updateHeader(evAccount).catch(console.error);
        }
    }, [evAccount]); // eslint-disable-line react-hooks/exhaustive-deps

    const setEvAccountCallback = async account => {
        if (account) {
            try {
                setEvAccount(account);
                const checkinsRefreshed = await initEvAccountData(account);
                if (!checkinsRefreshed) {
                    await getCheckinsLocal(account);
                }
            } catch (e) {
                console.log(e);
            }
        }
    };

    useEffect(() => {
        (apiErrors || apiMessages) && setOpenMessage(true);
    }, [apiErrors, apiMessages]);

    return (
        <SafeAreaView style={global.containerBg}>
            {progressOngoing && <LinearProgress color={theme.colors.primary} variant="indeterminate" />}
            <MessageDialog
                message={t(LOGIN_REFRESH_DIALOG_TEXT)}
                isError={false}
                open={openUserReauth}
                handleOpen={setOpenUserReauth}
            />
            <View style={global.filterNav}>
                <View
                    style={{
                        width: '100%',
                        maxWidth: 1070,
                        marginHorizontal: 'auto',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                    <Dropdown
                        position="right"
                        currentSortOption={sortCheckinsBy}
                        onSelected={onSelectedSortOption}
                        dropdownOptions={dropdownSortOptions}
                    />
                    <Dropdown
                        position="left"
                        currentSortOption={yearFilter}
                        dropdownOptions={dropdownYearOptions}
                        onSelected={onSelectedYear}
                        defaultCurrentYear={true}
                    />
                </View>
            </View>
            <CheckinsList
                checkins={checkins}
                evAccount={evAccount}
                refreshing={refresh}
                onRefresh={onRefresh}
                evaccountsNumber={evaccounts.length}
                openEvisitorModal={openEvisitorModal}
                startupCompleted={startupCompleted}
                sortCheckinsBy={sortCheckinsBy}
                getNextPageCheckins={getNextPageCheckins}
                hasNextCheckins={hasNextCheckins}
                yearFilter={yearFilter}
            />
            <MultiStepCheckinCreatorDialog evAccount={evAccount} navigation={navigation} />
            <AddEvisitorDialog
                open={openAddEvisitor}
                setOpen={setOpenAddEvisitor}
                onConnect={onConnectEvisitorAccount}
                evAccountError={evAccountError}
                setEvAccountError={setEvAccountError}
            />
            {isFocused && (
                <EVChooserDialog
                    evaccounts={evaccounts}
                    setEvAccount={setEvAccountCallback}
                    useSharedDialogs={useSharedDialogs}
                />
            )}
            {isFocused && (
                <MessageDialog
                    message={generateMessage()}
                    isError={apiErrors}
                    open={openMessage}
                    handleOpen={setOpenMessage}
                />
            )}
            {evaccounts.length !== 0 && <FabButton t={t} global={global} />}
        </SafeAreaView>
    );
};

export default CheckinsScreen;
