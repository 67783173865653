import {CHECKIN_ID, CREATED_AT, STAY_FROM_FIELD, UPDATED_AT, USER_ID, guests, id} from '../constants/stringsAndFields';
import {getCurrentYear, getNowDateSqlite} from '../utils/dateHelper';
import {
    execSqlCmd,
    execSqlCmdThrowable,
    parseSqlCountResults,
    parseSqlExistsResults,
    parseSqlResults,
    runSqlCmd,
} from './sqlOperations';

export const FORCE_DB_CREATE = false;

export const OFFLINE_EVISITOR_FORCE = true;
export const OFFLINE_STORAGE_SUPPORTED = true;
export const OFFLINE_MODE = true;
export const SQL_DEBUG = false;

export const database_name = 'echeckin.db';
export const database_version = '1.0';
export const database_displayname = 'EcheckinDatabase';
export const database_size = 200000;

const TIME_FIELDS = [CREATED_AT, UPDATED_AT];
const UPDATE_TIME_FIELD = [UPDATED_AT];
const EXCLUDE_UPDATE_FIELDS = [id, CHECKIN_ID, guests];
export const SQL_TIME_NOW = 'CURRENT_TIMESTAMP';

export const insertData = async (tx, table, fields, data) => {
    addTimestamps(data);
    const queryKeyNames = fields.join(',');
    const queryKeyValues = fields.map(key => `${serializeData(data, key)}`).join(',');
    const addQuery = `INSERT OR REPLACE INTO ${table} (${queryKeyNames}) VALUES (${queryKeyValues});`;
    SQL_DEBUG && console.log('addQuery: ' + addQuery);
    await runSqlCmd(tx, addQuery);
};

export const deleteData = async (tx, table, dataId = null) => {
    const whereClause = dataId ? `WHERE ${id} = '${dataId}'` : '';
    const delQuery = `DELETE FROM ${table} ${whereClause};`;
    SQL_DEBUG && console.log('delQuery: ' + delQuery);
    await runSqlCmd(tx, delQuery);
};

export const updateData = async (tx, table, data, idField = id) => {
    addUpdateTimestamps(data);
    const dataId = data[idField];
    const queryKeyNames = Object.keys(data);
    const filteredKeys = queryKeyNames.filter(key => !EXCLUDE_UPDATE_FIELDS.includes(key));
    const setQueryValues = filteredKeys.map(key => `${key} = ${serializeData(data, key)}`); //.slice(0, -1)
    const updateQuery = `UPDATE ${table} SET ${setQueryValues} WHERE ${idField} = '${dataId}';`;
    SQL_DEBUG && console.log('updateQuery: ' + updateQuery);
    await runSqlCmd(tx, updateQuery);
};

export const selectData = async (tx, table, fields, filter = id, filterData = null, orderBy = null) => {
    const queryKeyNames = fields.join(',');
    const whereClause = filterData ? `WHERE ${filter} = '${filterData}'` : '';
    const orderByClause = orderBy ? `ORDER BY ${orderBy} DESC` : '';
    const selectQuery = `SELECT ${queryKeyNames} FROM ${table} ${whereClause} ${orderByClause};`;
    SQL_DEBUG && console.log('selectQuery: ' + selectQuery);
    const dataResults = [];
    const queryResults = await execSqlCmd(tx, selectQuery);
    return parseSqlResults(queryResults, dataResults);
};

export const selectDataFromYear = async (
    tx,
    table,
    fields,
    filter = id,
    filterData = null,
    orderBy = null,
    year = getCurrentYear()
) => {
    const queryKeyNames = fields.join(',');
    const whereClause = filterData ? `WHERE ${filter} = '${filterData}'` : '';
    const orderByClause = orderBy ? `ORDER BY ${orderBy} DESC` : '';
    const selectQuery = `SELECT ${queryKeyNames} FROM ${table} ${whereClause} AND`;
    const dataResults = [];
    const selectFromYearQuery = `${selectQuery} strftime('%Y', ${CREATED_AT}) = '${year}' ${orderByClause};`;
    SQL_DEBUG && console.log('selectQuery: ' + selectFromYearQuery);
    const queryResults = await execSqlCmd(tx, selectFromYearQuery);
    return parseSqlResults(queryResults, dataResults);
};

export const selectDataFromStayYear = async (
    tx,
    table,
    fields,
    filter = id,
    filterData = null,
    orderBy = null,
    year = getCurrentYear()
) => {
    const queryKeyNames = fields.join(',');
    const whereClause = filterData ? `WHERE ${filter} = '${filterData}'` : '';
    const orderByClause = orderBy ? `ORDER BY ${orderBy} DESC` : '';
    const selectQuery = `SELECT ${queryKeyNames}, substr(${STAY_FROM_FIELD},1,4) as stay_from_year FROM ${table} ${whereClause} AND`;
    const dataResults = [];
    const selectFromYearQuery = `${selectQuery} stay_from_year = '${year}' ${orderByClause};`;
    SQL_DEBUG && console.log('selectQuery: ' + selectFromYearQuery);
    const queryResults = await execSqlCmd(tx, selectFromYearQuery);
    return parseSqlResults(queryResults, dataResults);
};

// not used currently
export const selectDataFromDate = async (
    tx,
    table,
    fields,
    filter = id,
    filterData = null,
    orderBy = null,
    date = getNowDateSqlite()
) => {
    const queryKeyNames = fields.join(',');
    const whereClause = filterData ? `WHERE ${filter} = '${filterData}'` : '';
    const orderByClause = orderBy ? `ORDER BY ${orderBy} DESC` : '';
    const selectQuery = `SELECT ${queryKeyNames} FROM ${table} ${whereClause} AND`;
    const dataResults = [];
    const selectFromDateQuery = `${selectQuery} strftime('%Y %m %d', ${CREATED_AT}) = '${date}' ${orderByClause};`;
    SQL_DEBUG && console.log('selectQuery: ' + selectFromDateQuery);
    const queryResults = await execSqlCmd(tx, selectFromDateQuery);
    return parseSqlResults(queryResults, dataResults);
};

export const selectDataMatchString = async (
    tx,
    table,
    fields,
    filter = id,
    filterData = null,
    orderBy = null,
    matchKey = null,
    matchValue = null
) => {
    const queryKeyNames = fields.join(',');
    const whereClause = filterData ? `WHERE ${filter} = '${filterData}'` : '';
    const orderByClause = orderBy ? `ORDER BY ${orderBy} DESC` : '';
    const selectQuery = `SELECT ${queryKeyNames} FROM ${table} ${whereClause} AND`;
    const dataResults = [];
    const selectFromMatchQuery = `${selectQuery} ${matchKey} = '${matchValue}' ${orderByClause};`;
    SQL_DEBUG && console.log('selectQuery: ' + selectFromMatchQuery);
    const queryResults = await execSqlCmd(tx, selectFromMatchQuery);
    return parseSqlResults(queryResults, dataResults);
};

export const selectDataNotEqual = async (tx, table, fields, filter = id, filterData = null, orderBy = null) => {
    const queryKeyNames = fields.join(',');
    const whereClause = filterData ? generateMultiWhere(filter, filterData) : '';
    const orderByClause = orderBy ? `ORDER BY ${orderBy} DESC` : '';
    const selectQuery = `SELECT ${queryKeyNames} FROM ${table} ${whereClause} ${orderByClause};`;
    SQL_DEBUG && console.log('selectQuery: ' + selectQuery);
    const dataResults = [];
    const queryResults = await execSqlCmd(tx, selectQuery);
    return parseSqlResults(queryResults, dataResults);
};

export const selectDataNotEqualFromYear = async (
    tx,
    table,
    fields,
    filter = id,
    filterData = null,
    orderBy = null,
    year = getCurrentYear()
) => {
    const queryKeyNames = fields.join(',');
    const whereClause = filterData ? generateMultiWhere(filter, filterData) : '';
    const orderByClause = orderBy ? `ORDER BY ${orderBy} DESC` : '';
    const selectQuery = `SELECT ${queryKeyNames} FROM ${table} ${whereClause} AND`;
    const selectFromYearQuery = `${selectQuery} strftime('%Y', ${CREATED_AT}) = '${year}' ${orderByClause};`;
    SQL_DEBUG && console.log('selectQuery: ' + selectFromYearQuery);
    const dataResults = [];
    const queryResults = await execSqlCmd(tx, selectFromYearQuery);
    return parseSqlResults(queryResults, dataResults);
};

const generateMultiWhere = (filterName, filterValues) => {
    let cmd = '';
    if (filterValues?.[0]) {
        cmd = `WHERE ${filterName} <> '${filterValues[0]}' `;
        filterValues.forEach((value, index) => {
            if (index !== 0) {
                cmd = cmd + `AND ${filterName} <> '${value}' `;
            }
        });
    }
    return cmd;
};

export const selectDataMultiFilter = async (
    tx,
    table,
    fields,
    filter1 = USER_ID,
    filterData1 = null,
    filter2 = id,
    filterData2 = null,
    orderBy = null
) => {
    const queryKeyNames = fields.join(',');
    const whereClause =
        filterData1 && filterData2 ? `WHERE ${filter1} = '${filterData1}' AND ${filter2} = '${filterData2}'` : '';
    const orderByClause = orderBy ? `ORDER BY ${orderBy} DESC` : '';
    const selectQuery = `SELECT ${queryKeyNames} FROM ${table} ${whereClause} ${orderByClause};`;
    SQL_DEBUG && console.log('selectQuery: ' + selectQuery);
    const dataResults = [];
    const queryResults = await execSqlCmd(tx, selectQuery);
    return parseSqlResults(queryResults, dataResults);
};

export const checkTableExists = async (tx, table) => {
    const queryResults = await execSqlCmd(tx, `SELECT name FROM sqlite_master WHERE type='table' AND name='${table}';`);
    return parseSqlExistsResults(queryResults);
};

// other possible check: SELECT COUNT(*) AS CNTREC FROM pragma_table_info('tablename') WHERE name='column_name'
export const checkColumnExist = async (tx, table, column) => {
    try {
        //await execSqlCmdThrowable(tx, `SELECT '${column}' FROM '${table}'`);
        await execSqlCmdThrowable(tx, `SELECT ${column} FROM ${table} LIMIT 1;`);
        return true;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const getTableCount = async (tx, table) => {
    const queryResults = await execSqlCmd(tx, `SELECT count(*) FROM ${table}`);
    return parseSqlCountResults(queryResults);
};

export const getTableCountByFilter = async (tx, table, filter) => {
    const queryResults = await execSqlCmd(tx, `SELECT count(*) FROM ${table} WHERE ${filter};`);
    return parseSqlCountResults(queryResults);
};

export const checkRowDataExists = async (tx, table, filter) => {
    const queryResults = await execSqlCmd(tx, `SELECT count(*) FROM ${table} WHERE ${filter};`);
    return parseSqlCountResults(queryResults);
};

const addTimestamps = data => {
    for (let timeField of TIME_FIELDS) {
        if (data[timeField] == null) {
            data[timeField] = SQL_TIME_NOW;
        }
    }
};

const addUpdateTimestamps = data => {
    data[UPDATE_TIME_FIELD] = SQL_TIME_NOW;
};

const serializeData = (data, key) => {
    const val = data?.[key] ?? null;
    const out = typeof val === 'object' ? JSON.stringify(val) : val;
    if (typeof out === 'boolean') return out === true ? 1 : 0;
    if (typeof out === 'number') return out; // without ''
    return out === SQL_TIME_NOW ? out : `'${escapeSingleQuote(out)}'`; // timestamp or 'string'
};

const escapeSingleQuote = text => (text.includes("'") ? text.replaceAll("'", "''") : text);

/*
CHECKIN BACKEND
id: uuid.UUID = db.Column(db.UUID, default=uuid.uuid4, primary_key=True)
user_id: uuid.UUID = db.Column(db.UUID, db.ForeignKey('users.id'), nullable=False)
status: str = db.Column(db.String(255), nullable=False)
StayFrom: str = db.Column(db.String(255), nullable=False)
ForeseenStayUntil: str = db.Column(db.String(255), nullable=False)
TimeStayFrom: str = db.Column(db.String(255), nullable=False)
TimeEstimatedStayUntil: str = db.Column(db.String(255), nullable=False)
additional_info: Dict = db.Column(JSONB, default={})
created_at: datetime.datetime = db.Column(db.DateTime, default=datetime.datetime.utcnow)
updated_at: datetime.datetime = db.Column(db.DateTime, onupdate=datetime.datetime.utcnow)

CHECKIN JS
const newCheckin = {
    [FORESEEN_STAY_UNTIL_FIELD]: checkinTime[FORESEEN_STAY_UNTIL_FIELD] ?? getTomorrowDate(),
    [id]: newCheckinId,
    [STAY_FROM_FIELD]: checkinTime[STAY_FROM_FIELD] ?? getNowDate(),
    [TIME_ESTIMATED_STAY_UNTIL_FIELD]: checkinTime[TIME_ESTIMATED_STAY_UNTIL_FIELD] ?? getNowDate(),
    [TIME_STAY_FROM_FIELD]: checkinTime[TIME_STAY_FROM_FIELD] ?? getNowDate(),
    [ADDITIONAL_FIELDS]: {
        [DATE_CREATED]: Date.now(),
        [EVISITOR]: {
            [NAME]: location?.[EV_ACCOUNT]?.[NAME],
            [PIN_ID]: location?.[EV_ACCOUNT]?.[PIN_ID]
        },
        [FACILITY_FIELD]: {
            [NAME]: location?.[FACILITY_FIELD]?.[NAME],
            [FACILITY_CODE]: facilityCode,
            [NAME_CODE]: location?.[FACILITY_FIELD]?.[NAME_CODE]
        },
        [ACCOMODATION]: {
            [NAME_CODE]: ""
        },
        [MAX_GUEST_NUMBER]: numberOfGuests
    }
}

id: uuid.UUID = db.Column(db.UUID, default=uuid.uuid4, primary_key=True)
# One checkin to one user tokens relationship
user_id: uuid.UUID = db.Column(db.UUID, db.ForeignKey('users.id'), nullable=False)
checkin_id: uuid.UUID = db.Column(db.UUID, db.ForeignKey('checkin.id'), nullable=False)
content: Dict = db.Column(JSONB, default={})
status: str = db.Column(db.String(255), nullable=False)
created_at: datetime.datetime = db.Column(db.DateTime, default=datetime.datetime.utcnow)
updated_at: datetime.datetime = db.Column(db.DateTime, onupdate=datetime.datetime.utcnow)

[CHECKIN_ID]: checkin[id],
[id]: guestId,
[STATUS]: EDITED_STATUS,
[content]:
*/
