import {PermissionsAndroid, Platform} from 'react-native';
import {
    CHECKED_IN_STATUS_LABEL,
    CHECKED_OUT_STATUS_LABEL,
    CITIZENSHIP,
    DATE_TIME_OF_ARRIVAL,
    EV_ACCOUNTS_DATA,
    EV_ACCOUNT_ID,
    EXPORT_INVOICE_DATE,
    EXPORT_INVOICE_NAME,
    EXPORT_INVOICE_NUMBER,
    EXPORT_INVOICE_ORDINAL,
    EXPORT_INVOICE_PAYMENT_TYPE,
    EXPORT_INVOICE_PRICE,
    GENDER_FIELD,
    ID,
    INVOICES_DATASET,
    INVOICE_FORM_CLIENT,
    INVOICE_FORM_DATE,
    INVOICE_FORM_GUEST,
    INVOICE_FORM_NUMBER,
    INVOICE_FORM_PAYMENT_TYPE,
    INVOICE_FORM_PRICE_FINAL,
    NAME,
    PIN,
    TOURISTS_DATASET,
    TOURIST_CHECKOUT_OUT,
    TOURIST_SURNAME_NAME,
    content,
} from '../constants/stringsAndFields';
import {
    getDateFromAspNetFormat,
    getDateFromDateString,
    getDateString,
    getFormatedRange,
    isInsideDateRange,
} from './dateHelper';
import {writeInvoicesToXlsxWithHeader} from './exportInvoices';
import {writeTouristsToXlsxWithHeader} from './exportTourists';
import {getAccomodationData, getTouristDates} from './guestUtils';
import {getAgeRangeFromName, getCountryShortName, isInvoiceTypeProforma, isStringNotEmpty} from './helpers';
import {sortByDate} from './invoice/invoice.functions';
import {getUserInfo} from './userUtils';
import {isEmpty} from './arrayHelpers';

export const EXPORT_CHECKIN_FILE_NAME = 'eCheckin_Guests.xlsx';
export const EXPORT_INVOICE_FILE_NAME = 'eCheckin_Invoices.xlsx';
export const EXPORT_INVOICE_USER = 'eCheckin';

export const exportTouristsToXlsx = async (tourists, evAccounts, dateRange, countries, t) => {
    if (tourists && tourists.length > 0 && evAccounts) {
        const exportData = [];
        for (let tourist of tourists) {
            try {
                const evAccount = evAccounts.find(ev => ev[ID] === tourist[EV_ACCOUNT_ID]);
                if (evAccount) {
                    const touristStayFromDate = getDateFromAspNetFormat(tourist?.[DATE_TIME_OF_ARRIVAL]);
                    if (isInsideDateRange(dateRange?.startDate, dateRange?.endDate, touristStayFromDate)) {
                        try {
                            const ownerOib = evAccount?.[PIN];
                            const ownerName = evAccount?.[NAME];

                            const {facilityName, accomodationUnit} = getAccomodationData(evAccount, tourist);
                            const {touristStayFrom, touristForseenStayUntil} = getTouristDates(tourist);

                            const touristName = tourist?.[TOURIST_SURNAME_NAME];
                            const touristStatus = tourist?.[TOURIST_CHECKOUT_OUT]
                                ? t(CHECKED_OUT_STATUS_LABEL)
                                : t(CHECKED_IN_STATUS_LABEL);

                            const countryShort = getCountryShortName(countries, tourist);
                            const ageRange = getAgeRangeFromName(touristName);
                            const genderMale = tourist[GENDER_FIELD]?.startsWith('M');

                            exportData.push({
                                ownerOib,
                                ownerName,
                                facilityName,
                                accomodationUnit,
                                touristName,
                                touristStayFrom,
                                touristForseenStayUntil,
                                touristStatus,
                                countryShort,
                                ageRange,
                                genderMale,
                            });
                        } catch (e) {
                            console.error('Error:', e);
                        }
                    }
                }
            } catch (error) {
                console.error(`Error processing tourist data: ${tourist}`, error);
            }
        }
        try {
            const userInfoData = await getUserInfo();
            await writeTouristsToXlsxWithHeader(
                exportData,
                {[EV_ACCOUNTS_DATA]: evAccounts, ...userInfoData, dateRange: getFormatedRange(dateRange)},
                EXPORT_CHECKIN_FILE_NAME,
                t
            );
        } catch (e) {
            console.error('Error while exporting tourists to XLSX', e);
        }
    }
};

export const exportInvoicesToXlsx = async (invoices, evAccounts, dateRange, t) => {
    if (invoices && invoices.length > 0 && evAccounts) {
        const exportData = [];
        let invoiceOrdinal = 1;
        invoices.sort(sortByDate).reverse();
        for (let invoice of invoices) {
            try {
                if (!isInvoiceTypeProforma(invoice[content])) {
                    const invoiceDate = getDateFromDateString(
                        invoice?.[content]?.[INVOICE_FORM_DATE] ?? invoice?.[INVOICE_FORM_DATE]
                    );
                    if (isInsideDateRange(dateRange?.startDate, dateRange?.endDate, invoiceDate)) {
                        try {
                            const guestName = isStringNotEmpty(invoice?.[content]?.[INVOICE_FORM_GUEST])
                                ? invoice?.[content]?.[INVOICE_FORM_GUEST]
                                : invoice?.[content]?.[INVOICE_FORM_CLIENT] ?? '';

                            exportData.push({
                                [t(EXPORT_INVOICE_ORDINAL)]: invoiceOrdinal,
                                [t(EXPORT_INVOICE_NUMBER)]: invoice?.[content]?.[INVOICE_FORM_NUMBER],
                                [t(EXPORT_INVOICE_DATE)]: getDateString(invoice?.[content]?.[INVOICE_FORM_DATE]),
                                [t(EXPORT_INVOICE_NAME)]: guestName,
                                [t(EXPORT_INVOICE_PRICE)]: invoice?.[content]?.[INVOICE_FORM_PRICE_FINAL],
                                [t(EXPORT_INVOICE_PAYMENT_TYPE)]: t(invoice?.[content]?.[INVOICE_FORM_PAYMENT_TYPE]),
                            });
                            invoiceOrdinal++;
                        } catch (e) {
                            console.error('Error:', e);
                        }
                    }
                }
            } catch (error) {
                console.error(`Error processing invoice data: ${invoice}`, error);
            }
        }
        try {
            if (!isEmpty(exportData)) {
                const userInfoData = await getUserInfo();
                await writeInvoicesToXlsxWithHeader(
                    exportData,
                    {[EV_ACCOUNTS_DATA]: evAccounts, ...userInfoData, dateRange: getFormatedRange(dateRange)},
                    EXPORT_INVOICE_FILE_NAME,
                    t
                );
            }
        } catch (e) {
            console.error('Error while exporting invoices to XLSX', e);
        }
    }
};

const exportDataSet = async (datasetOne, datasetTwo, datasetType, dateRange, countries, t) => {
    if (datasetType === INVOICES_DATASET) {
        await exportInvoicesToXlsx(datasetOne, datasetTwo, dateRange, t);
    } else if (datasetType === TOURISTS_DATASET) {
        await exportTouristsToXlsx(datasetOne, datasetTwo, dateRange, countries, t);
    }
};

export const handleExportDataToXlsx = async (datasetOne, datasetTwo, datasetType, dateRange, countries, t) => {
    await exportDataSet(datasetOne, datasetTwo, datasetType, dateRange, countries, t);
    return;
    if (Platform.OS === 'android') {
        try {
            // Check for Permission (check if permission is already given or not)
            let isPermitedExternalStorage = await PermissionsAndroid.check(
                PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE
            );

            if (!isPermitedExternalStorage) {
                // Ask for permission
                const granted = await PermissionsAndroid.request(
                    PermissionsAndroid.PERMISSIONS.WRITE_EXTERNAL_STORAGE,
                    {
                        title: 'Storage permission needed',
                        buttonNeutral: 'Ask Me Later',
                        buttonNegative: 'Cancel',
                        buttonPositive: 'OK',
                    }
                );

                if (granted === PermissionsAndroid.RESULTS.GRANTED) {
                    // Permission Granted (calling our exportDataToExcel function)
                    //await exportTouristsToXlsx(tourists, evAccount, dateRange, t);
                    await exportDataSet(datasetOne, datasetTwo, datasetType, dateRange, t);
                } else {
                    // Permission denied
                    console.log('Permission denied');
                }
            } else {
                // Already have Permission (calling our exportDataToExcel function)
                //await exportTouristsToXlsx(tourists, evAccount, dateRange, t);
                await exportDataSet(datasetOne, datasetTwo, datasetType, dateRange, t);
            }
        } catch (e) {
            console.log('Error while checking permission');
            console.error('Error:', e);
            return;
        }
    } else {
        // ios and web can directly export
        await exportDataSet(datasetOne, datasetTwo, datasetType, dateRange, t);
    }
};
